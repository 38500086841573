import { AuthenticationLayout } from 'components/AuthenticationLayout';
import ProtectedRoute from 'components/ProtectedRoute';
import AdminDashboard from 'pages/Admin/AdminDashboard';
import AddBusinessAccount from 'pages/Admin/NewBusinessAccount/AddBusinessAccount';
import InviteSignUp from 'pages/InviteSignUp';
import SignUP from 'pages/SignUP';
import SignUpByInvite from 'pages/SignUpByInvite';
import SplashScreen from 'pages/SplashScreen';
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { USER_ROLES } from 'utils/constants';
import { Layout } from './components/Layout';

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SplashScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const routePaths = {
  home: '',
  login: 'login',
};

const ProjectsDashBoard = Loadable(lazy(() => import('./pages/Projects')));
const NewProjectPage = Loadable(
  lazy(() => import('./pages/Projects/NewProject'))
);
const EditProjectPage = Loadable(
  lazy(() => import('./pages/Projects/EditProject'))
);

const LoginPage = Loadable(lazy(() => import('./pages/Login')));
const AdminLoginPage = Loadable(lazy(() => import('./pages/AdminLogin')));
const NoMatchPage = Loadable(lazy(() => import('./pages/NoMatch')));
const ForgotPasswordPage = Loadable(
  lazy(() => import('./pages/ForgotPassword'))
);
const ResetPasswordPage = Loadable(
  lazy(() => import('./pages/ResetPassword'))
);
const SignupPage = SignUP;
const ContactUsPage = Loadable(lazy(() => import('./pages/ContactUs')));
const HowItWorksPage = Loadable(lazy(() => import('./pages/HowItWorks')));
const PricingPage = Loadable(lazy(() => import('./pages/Pricing')));
const EditUserPage = Loadable(lazy(() => import('./pages/UserEdit/EditUser')));
const RevisionsPage = Loadable(
  lazy(() => import('./pages/Revisions/index'))
);
const EditBusinessPage = Loadable(
  lazy(() => import('./pages/BusinessAccount/EditBusinessAccount'))
);

const RevisionResultPage = Loadable(
  lazy(() => import('./pages/Revisions/RevisionResult'))
);

const AddRevisionPage = Loadable(
  lazy(() => import('./pages/Revisions/NewRevision'))
);

const EditRevisionPage = Loadable(
  lazy(() => import('./pages/Revisions/EditRevision'))
);

const EditRevisionTicketDSBPage = Loadable(
  lazy(() => import('./pages/Revisions/EditTicketDsb'))
);

const AdminTicketsPage = Loadable(
  lazy(() => import('./pages/Admin/AdminTickets'))
);

const AdminOpenTicketPage = Loadable(
  lazy(() => import('./pages/Admin/ticket_resolution/AdminOpenTicketPage'))
);

export const getRoutes = (role?: string): RouteObject[] => {
  return role && role.toLowerCase() === USER_ROLES.ADMIN
    ? [...AdminUserRoutes, ...publicRoutes]
    : [...AuthUserRoutes, ...publicRoutes];
};

export const publicRoutes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        path: '/login',
        element: <AuthenticationLayout page='login' />,
        children: [
          { index: true, element: <LoginPage /> },
          { path: '*', element: <LoginPage /> },
        ],
      },
      {
        path: '/forgot',
        element: <AuthenticationLayout page='forgot' />,
        children: [
          { index: true, element: <ForgotPasswordPage /> },
          { path: '*', element: <ForgotPasswordPage /> },
        ],
      },
      {
        path: '/reset/FORGOT/:token',
        element: <AuthenticationLayout page='reset' />,
        children: [
          { index: true, element: <ResetPasswordPage /> },
          { path: '*', element: <ResetPasswordPage /> },
        ],
      },
      {
        path: '/admin/login',
        element: <AuthenticationLayout page='adminLogin' />,
        children: [
          { index: true, element: <AdminLoginPage /> },
          { path: '*', element: <AdminLoginPage /> },
        ],
      },
      {
        path: '/signup',
        element: <Layout />,
        children: [
          { index: true, element: <SignupPage /> },
          { path: '*', element: <SignupPage /> },
        ],
      },
      {
        path: '/contact',
        element: <Layout />,
        children: [
          { index: true, element: <ContactUsPage /> },
          { path: '*', element: <ContactUsPage /> },
        ],
      },
      {
        path: '/how-it-works',
        element: <Layout />,
        children: [
          { index: true, element: <HowItWorksPage /> },
          { path: '*', element: <HowItWorksPage /> },
        ],
      },
      {
        path: '/pricing',
        element: <Layout />,
        children: [
          { index: true, element: <PricingPage /> },
          { path: '*', element: <PricingPage /> },
        ],
      },
      {
        path: '/invite/gate/:invitationDetails',
        element: <Layout />,
        children: [
          { index: true, element: <InviteSignUp /> },
          { path: '*', element: <InviteSignUp /> },
        ],
      },
      {
        path: '/signUpByInvite',
        element: <Layout />,
        children: [
          { index: true, element: <SignUpByInvite /> },
          { path: '*', element: <SignUpByInvite /> },
        ],
      },
    ],
  },
];

export const AuthUserRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <ProjectsDashBoard />
          </ProtectedRoute>
        ),
      },
      {
        path: '/add-project',
        element: (
          <ProtectedRoute>
            <NewProjectPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/edit-project/:projectId',
        element: (
          <ProtectedRoute>
            <EditProjectPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/edit/business',
        element: (
          <ProtectedRoute>
            <EditBusinessPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/edit/user',
        element: (
          <ProtectedRoute>
            <EditUserPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/project/:projectId/board',
        element: (
          <ProtectedRoute>
            <RevisionsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/project/:projectId/add-revision',
        element: (
          <ProtectedRoute>
            <AddRevisionPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/business/:businessId/project/:projectId/edit-revision/:revisionId',
        element: (
          <ProtectedRoute>
            <EditRevisionPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/business/:businessId/project/:projectId/revision/:revisionId/result',
        element: (
          <ProtectedRoute>
            <RevisionResultPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/project/:projectId/edit-ticket-dsb/:revisionId',
        element: (
          <ProtectedRoute>
            <EditRevisionTicketDSBPage />
          </ProtectedRoute>
        ),
      },
      { path: '*', element: <NoMatchPage /> },
    ],
  },
];

export const AdminUserRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/dashboard',
        element: (
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/tickets',
        element: (
          <ProtectedRoute>
            <AdminTicketsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/business/:businessId/projects',
        element: (
          <ProtectedRoute>
            {/* <AdminBusinessProjects /> */}
            <ProjectsDashBoard />
          </ProtectedRoute>
        ),
      },
      {
        path: '/edit-project/:projectId/:businessId',
        element: (
          <ProtectedRoute>
            <EditProjectPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/edit/business/:businessId',
        element: (
          <ProtectedRoute>
            <EditBusinessPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/add-business',
        element: (
          <ProtectedRoute>
            <AddBusinessAccount />
          </ProtectedRoute>
        ),
      },
      {
        path: '/add-project/:businessId',
        element: (
          <ProtectedRoute>
            <NewProjectPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/business/:businessId/project/:projectId/board',
        element: (
          <ProtectedRoute>
            <RevisionsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/business/:businessId/project/:projectId/revision/:revisionId/result',
        element: (
          <ProtectedRoute>
            <RevisionResultPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/business/:businessId/project/:projectId/add-revision',
        element: (
          <ProtectedRoute>
            <AddRevisionPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/business/:businessId/project/:projectId/edit-revision/:revisionId',
        element: (
          <ProtectedRoute>
            <EditRevisionPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/business/:businessId/project/:projectId/edit-ticket-dsb/:revisionId',
        element: (
          <ProtectedRoute>
            <EditRevisionTicketDSBPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/business/:businessId/project/:projectId/revision/:revisionId/:ticketId',
        element: (
          <ProtectedRoute>
            <AdminOpenTicketPage />
          </ProtectedRoute>
        ),
      },

      { path: '*', element: <NoMatchPage /> },
    ],
  },
];