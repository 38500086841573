import { TableCell, TableCellProps } from '@mui/material';
import React from 'react';

interface CustomTableCellProps extends TableCellProps {
  children: React.ReactNode;
  disabled?: boolean;
}

const CustomTableCell: React.FC<CustomTableCellProps> = ({ children, disabled = false, ...props }) => {
  return (
    <TableCell
      sx={{
        height: '54px',
        padding: '0 16px',
        ...(disabled && { color: 'rgba(0, 0, 0, 0.38)' }),
      }}
      {...props}
    >
      {children}
    </TableCell>
  );
};

export default CustomTableCell;