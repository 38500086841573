import AddIcon from '@mui/icons-material/Add';
import { TableRow } from '@mui/material';
import CustomTableCell from 'components/CustomTableCell';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { calcCreditedPoints } from 'utils/Points';
const CreditTransaction = ({ key, transaction }: any) => {
  const { t } = useTranslation();

  const formatMessage = (transaction: any) => {
    return `${t('BUSINESS_SETTING_TRANSACTION_PACKAGE')} ${
      transaction.plan_title
    }`;
  };

  const formatPoints = (transaction: any) => {
    if (transaction.monthly === true) {
      return `${calcCreditedPoints(transaction)}/${transaction.points}`;
    } else {
      return transaction.points > 0 ? transaction.points : '';
    }
  };

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      key={key}
    >
      <CustomTableCell align='center' component='th' scope='row' sx={{padding:0}}>
        <AddIcon />
      </CustomTableCell>
      <CustomTableCell>
        {moment.utc(transaction.createdAt).local().format('DD/MM/YY')}{' '}
        <span className='table-time'>
          {moment.utc(transaction.createdAt).local().format('HH:mm')}
        </span>
      </CustomTableCell>
      <CustomTableCell>{transaction.paidBy}</CustomTableCell>
      <CustomTableCell >{formatMessage(transaction)}</CustomTableCell>
      <CustomTableCell align='right'>
          {/*  {transaction.monthly ? (
            <img alt='recurring' src={Repeat} style={{ width: '10px' }} />
          ) : null} */}
          {formatPoints(transaction)}
      </CustomTableCell>
    </TableRow>
  );
};

export default CreditTransaction;
