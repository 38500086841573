export const en = {
  translation: {

  ADD_BUSINESS_SETTING_SAVE_BUTTON: "Save",
  ADD_PROJECT_CANCEL_BUTTON: "Cancel",
  ADD_PROJECT_CITY: "City",
  ADD_PROJECT_CLIMATE: "Climate",
  ADD_PROJECT_DATE: "Date",
  ADD_PROJECT_GUSH: "Gush",
  ADD_PROJECT_HEADING_1: "New Building",
  ADD_PROJECT_HEADING_2: "Fill all building details accurately",
  ADD_PROJECT_NAME: "Building Name",
  ADD_PROJECT_PARTITION: "Partition",
  ADD_PROJECT_SAVE_BUTTON: "Save",
  ADD_PROJECT_STREET: "Street",
  ADD_PROJECT_TERRAIN: "Terrain",
  ADD_PROJECT_TYPE: "Type",
  ADMIN_ADD_BUSINESS: "New Business",
  ADMIN_ADD_BUSINESS_CREDIT: "Credits",
  ADMIN_ADD_BUSINESS_CREDIT_BUTTON: "Save Transaction",
  ADMIN_ADD_BUSINESS_CREDIT_DESCRIPTION: "Description",
  ADMIN_ADD_BUSINESS_CREDIT_PACKAGE_SELECT: "Select package",
  ADMIN_ADD_BUSINESS_CREDIT_POINTS: "Credits",
  ADMIN_ADD_BUSINESS_CREDIT_SUM: "Sum",
  ADMIN_DASHBOARD_ADD_ORGANISATION: "New Business",
  ADMIN_DASHBOARD_DATE: "Date",
  ADMIN_DASHBOARD_EDIT_BUSINESS: "Edit Business",
  ADMIN_DASHBOARD_NAME: "Name",
  ADMIN_DASHBOARD_TITLE: "Business Accounts",
  ADMIN_LOGIN_GENERAL: "An error occurred",
  ADMIN_LOGIN_HEADER: "Admin",
  ADMIN_LOGIN_INVALID_CREDENTIALS: "Invalid password",
  ADMIN_LOGIN_PASSWORD: "Password",
  ADMIN_LOGIN_TEXT: "Login",
  ADMIN_ORGANISATION_SEARCH_PLACEHOLDER: "Search",
  ADMIN_TOPUP_GENERAL: "Transaction failed due to a server issue",
  BUSINESS_ADMIN_CREDIT_ADD_SUCESS: "Successful transaction",
  BUSINESS_CONTACT_INFO_UPDATE_SUCESS: "Contact information updated successfully",
  BUSINESS_EDIT_CONTACT_HEADER: "Contact information",
  BUSINESS_EDIT_CREDIT_HEADER: "Credits",
  BUSINESS_EDIT_HEADER: "Business Account",
  BUSINESS_EDIT_Setting_HEADER: "Business settings",
  BUSINESS_EDIT_SETTING_MEMBER_SUCESS: "Permission updated successfully",
  BUSINESS_EDIT_STAFF_HEADER: "Team members",
  BUSINESS_MENU_DELETE: "Delete business",
  BUSINESS_MENU_EDIT: "Edit business",
  BUSINESS_MENU_VIEW: "View Projects",
  BUSINESS_SETTING_MEMBER_ADD_BUTTON: "Save Permission",
  BUSINESS_SETTING_MEMBER_CANCEL_INVITE: "Cancel invite",
  BUSINESS_SETTING_MEMBER_EMAIL: "Email",
  BUSINESS_SETTING_MEMBER_PERMISSION: "Permission",
  BUSINESS_SETTING_MEMBER_PERMISSION_EMAIL: "Email",
  BUSINESS_SETTING_MEMBER_PERMISSION_MANAGER: "Manager",
  BUSINESS_SETTING_MEMBER_PERMISSION_MEMBER: "Member",
  BUSINESS_SETTING_MEMBER_PERMISSION_NAME: "Name",
  BUSINESS_SETTING_MEMBER_PERMISSION_ROLE: "Permission",
  BUSINESS_SETTING_MEMBER_PERMISSION_VIEWER: "Viewer",
  BUSINESS_SETTING_MEMBER_REMOVE_FROM_STAFF: "Remove member",
  BUSINESS_SETTING_MEMBER_RESEND_INVITE: "Resend invite",
  BUSINESS_SETTING_TRANSACTION_BY: "Member",
  BUSINESS_SETTING_TRANSACTION_CREDITS: "Credits",
  BUSINESS_SETTING_TRANSACTION_DATE: "Date",
  BUSINESS_SETTING_TRANSACTION_FOR: "For",
  BUSINESS_SETTING_TRANSACTION_PACKAGE: "Package",
  BUSINESS_SETTING_TRANSACTION_STATUS: "Status",
  BUSINESS_SETTING_UPDATE_SUCESS: "Business settings updated successfully",
  BUSINESS_STAFF_INVITE_CANCEL_SUCESS: "Invitation cancelled successfully",
  BUSINESS_STAFF_INVITE_RESEND_SUCESS: "Invitation sent successfully",
  BUSINESS_STAFF_INVITE_SUCESS: "Invitation sent successfully",
  BUSINESS_STAFF_REMOVE_SUCESS: "Team member removed successfully",
  CHANGE_EMAIL_GENERAL: "Unable to update email address. Try again later",
  CHANGE_PASSWORD_GENERAL: "Password update failed. Try again later",
  CHANGE_PASSWORD_NO_MATCH: "Passwords do not match",
  CHANGE_PERMISSIONS_GENERAL: "An error occurred",
  CHECK_PASSWORD_INVALID: "Invalid password",
  CLIMATE_A: "Climate A",
  CLIMATE_B: "Climate B",
  CLIMATE_C: "Climate C",
  CLIMATE_D: "Climate D",
  CONTACT_US_EMAIL: "Email",
  CONTACT_US_HEADER: "We're here for you!",
  CONTACT_US_HELP: "How can we help?",
  CONTACT_US_HELP_INSTRUCTION: "Training",
  CONTACT_US_HELP_RECOMMENDATION: "Recommendation",
  CONTACT_US_HELP_SUPPORT: "Support",
  CONTACT_US_HELP_TOPIC: "Subject",
  CONTACT_US_NAME: "Full Name",
  CONTACT_US_SEND: "Send",
  CONTACT_US_SUCCESS_HEADER: "Thank you for your inquiry",
  CONTACT_US_SUCCESS_HEADER1: "We'll get back to you soon",
  CONTACT_US_SUCCESS_MESSAGE: "Thank you for your inquiry. We'll get back to you soon.",
  CONTACT_US_TOPIC: "Subject",
  CONTACTUS_EMAIL_REQUIRED: "Required field",
  CONTACTUS_GENERAL: "Unable to send message. Try again later.",
  CONTACTUS_NAME_REQUIRED: "Required field",
  CONTACTUS_SUCCESS: "Thanks! We'll get back to you soon.",
  DELETE_ORGANIZATION_SUCCESS_MESSAGE: "Business account deleted successfully!",
  DELETE_REVISION_SUCCESS_MESSAGE: "Revision deleted successfully!",
  DESIGN_BUILDER: "DesignBuilder",
  DROPZONE_PLACEHOLDER: "Click or drop your image here",
  EDIT_BUSINESS_HEADER_BALANCE: "Total credits:",
  EDIT_BUSINESS_HEADER_VALID_UNTIL: "Valid until",
  EDIT_BUSINESS_SETTING_ADDRESS: "Address",
  EDIT_BUSINESS_SETTING_ADDRESS_HELPER_TEXT: "This address will appear in your reports",
  EDIT_BUSINESS_SETTING_EMAI_HELPER_TEXT: "This email will appear in your reports",
  EDIT_BUSINESS_SETTING_EMAIL: "Email",
  EDIT_BUSINESS_SETTING_HONE_HELPER_TEXT: "This phone will appear in your reports",
  EDIT_BUSINESS_SETTING_NAME: "Business Name",
  EDIT_BUSINESS_SETTING_NAME_HELPER_TEXT: "This name will appear in your reports",
  EDIT_BUSINESS_SETTING_PHONE: "Phone",
  EDIT_BUSINESS_SETTING_SAVE_BUTTON: "Save",
  EDIT_BUSINESS_TABLE_BY: "Member",
  EDIT_BUSINESS_TABLE_CREDITS: "Credit",
  EDIT_BUSINESS_TABLE_DATE: "Date",
  EDIT_BUSINESS_TABLE_FOR: "For",
  EDIT_BUSINESS_TABLE_STATUS: "Status",
  EDIT_PROJECT_HEADING_1: "Edit Building",
  EDIT_PROJECT_SAVE_BUTTON: "Save",
  EDIT_REVISION_TITLE: "Edit Alternative",
  EMAIL_CANNOT_EXISTS_DIFFERENT_ORGANIZATIONS: "Email associated with another business",
  EMAIL_MISMATCH: "Emails do not match",
  FILL_BETA_ORGANIZATION_GENERAL: "An error occurred",
  FORGOT_BTN_TEXT: "Reset password",
  FORGOT_EMAIL: "Email",
  FORGOT_EMAIL_HELPTEXT: "Your registered email address",
  FORGOT_EMAIL_INVALID: "Invalid email",
  FORGOT_EMAIL_NOT_EXIST: "Unrecognized email",
  FORGOT_EMAIL_REQUIRED: "Required field",
  FORGOT_GENERAL: "Could not retrieve user",
  FORGOT_HEADER: "Reset Password",
  FORGOT_PASSSWORD_SUCCESS_MESSAGE: "Check your inbox and spam for an email",
  FORGOT_SUCCESS: "Check your inbox and spam for an email",
  FORGOT_SUCCESS_HEADER: "Check your inbox and spam for an email",
  GENERAL_ERROR: "Something went wrong! Try again later.",
  HEADER_PROFILE_MENU_LOGOUT: "Logout",
  HEADER_PROJECT_LIST: "Projects",
  IMAGE_SIZE_ERROR: "Image too large, maximum 500KB",
  INVALID_CREDENTIALS: "The current password is incorrect",
  INVITE_EMAIL_ALREADY_INVITED: "Already invited",
  INVITE_EMAIL_INVALID: "Invalid email",
  INVITE_EMAIL_REQUIRED: "Required field",
  INVITE_GENERAL: "Could not invite user, try again later",
  INVITE_SIGNUP_BUTTON: "Join for free",
  INVITE_SIGNUP_CANCEL_BUTTON: "Cancel",
  INVITE_SIGNUP_EMAIL: "Email",
  INVITE_SIGNUP_EMAIL_HELPER_TEXT: "Email as displayed in the invitation",
  INVITE_SIGNUP_EMAIL_RECEIVE: "I want to receive updates and tips",
  INVITE_SIGNUP_NAME: "Full Name",
  INVITE_SIGNUP_NAME_HELPER_TEXT: "This name will appear in your reports",
  INVITE_SIGNUP_PASSWORD: "Password",
  INVITE_SIGNUP_TERMS: "I have read and approved the",
  INVITE_SIGNUP_TERMS_LINK: "Terms of use",
  INVITED_BY_TEAM: "Invited to the team",
  LOGIN_BTN_TEXT: "Login",
  LOGIN_EMAIL: "Email",
  LOGIN_EMAIL_HELPTEXT: "The registered email address",
  LOGIN_EMAIL_INVALID: "Invalid email",
  LOGIN_EMAIL_REQUIRED: "Required field",
  LOGIN_FORGOT_PASSWORD_BTN_TEXT: "Forgot Password?",
  LOGIN_GENERAL: "Could not login, try again later",
  LOGIN_HEADER: "Login to Energy Rating",
  LOGIN_INVALID_CREDENTIALS: "Invalid email or password",
  LOGIN_PASSWORD: "Password",
  LOGIN_PASSWORD_REQUIRED: "Required field",
  LOGIN_QUOTE: "Energy Rating Tool",
  LOGIN_REGISTER_BTN_TEXT: "Join Free",
  MENU_ADMIN_DASHBOARD: "Business Accounts",
  MENU_ADMIN_TICKETS: "Tickets",
  MENU_BUSINESS: "Business Account",
  MENU_CONTACT: "Contact Us",
  MENU_DASHBOARD: "Projects",
  MENU_EDIT_USER: "Personal Area",
  MENU_HOW_IT_WORKS: "How It Works",
  MENU_PRICING: "Pricing",
  NEW_EMAIL_DONT_MATCH: "Email does not match",
  NEW_REVISION_GREATER_100: "Calculated mass of the exterior walls > 100 kg/m2",
  NEW_REVISION_GUSH: "U-value of thermal bridges in external walls W/m2K",
  NEW_REVISION_INFO: "New buildings are required to meet all SI-5282 requirements. Compliance with SI-1045 according to Table 1 involves defining materials with conductivity and density. The H calculation of a single or two-family house is partial.",
  NEW_REVISION_INFO_1: "Data to calculate H according to SI-5280",
  NEW_REVISION_LESS_100: "Calculated mass of the exterior walls ≤ 100 kgm2",
  NEW_REVISION_PARTITION: "Percentage of thermal bridges in external walls (0-100)",
  NEW_REVISION_SIMULATOR_NAME: "Simulator Name",
  NEW_REVISION_TEXTAREA_PLACEHOLDER: "Description of the alternative (will not appear in the reports)",
  NEW_REVISION_TITLE: "New Alternative",
  NEWPASS_GENERAL: "Password could not be changed",
  NO_PROJECT_HEADING_1: "You have no projects yet",
  NO_PROJECT_HEADING_2: "To get started, click on New Building",
  NO_REVISION_HEADING_1: "You have no alternatives yet",
  NO_REVISION_HEADING_2: "To get started, click on New Alternative",
  NO_SEARCHRESULT_HEADING_1: "No matches found",
  ORG_REGISTRATION_SUCCESS_MESSAGE: "Thank you! We will get back to you soon",
  ORGANISATION_DELETE_NO: "Cancel",
  ORGANISATION_DELETE_POPUP_DESCRIPTION: "Are you sure you want to delete this Business Account?",
  ORGANISATION_DELETE_POPUP_TITLE: "Business Account Name",
  ORGANISATION_DELETE_YES: "Delete",
  PASSWORD_MISMATCH: "Passwords do not match",
  PAY_TOPUP_GENERAL: "Transaction failed due to a server issue",
  PIPE_DOWNLOAD_OUTPUT_GENERAL: "An error occurred",
  PRICING_DISABLED_TEXT: "Coming soon for purchase",
  PRICING_HEADER_1: "Saving you time and money on every project",
  PRICING_HEADER_2: "Energy cloud simulations and reports for each building",
  PRICING_MONTHLY_PLAN: "Purchase",
  PRICING_PLANS_FAILED: "An error occurred",
  PRICING_YEARLY_PLAN: "Purchase",
  PRIVATE_AREA_EMAIL_UPDATE_SUCESS: "Email updated successfully",
  PRIVATE_AREA_PASSWORD_UPDATE_SUCESS: "Password updated successfully",
  PRIVATE_AREA_PERSONAL_INFO_UPDATE_SUCESS: "Personal information updated successfully",
  PROJECT_CANNOT_DELETE_REVISIONS_SUCCESS: "Cannot delete a building with reports",
  PROJECT_CHANGE_COLOR: "Change color",
  PROJECT_CITY_REQUIRED: "Required field",
  PROJECT_CLIMATE_INVALID: "Invalid climate",
  PROJECT_CLIMATE_REQUIRED: "Required field",
  PROJECT_DELETE: "Delete building",
  PROJECT_DELETE_GENERAL: "The building could not be deleted, try again later",
  PROJECT_DELETE_SUCCESS: "Building deleted successfully",
  PROJECT_DELETE_UNAUTHORIZED: "Unauthorized to delete the building",
  PROJECT_EDIT_MENU: "Edit building",
  PROJECT_GENERAL: "The building could not be saved, try again later",
  PROJECT_IMAGE_TOO_BIG: "Image too large, maximum 500KB",
  PROJECT_LIST_TABLE_ALTERNATIVES: "Alternatives",
  PROJECT_LIST_TABLE_CLIMATE: "Climate",
  PROJECT_LIST_TABLE_DATE: "Date",
  PROJECT_LIST_TABLE_NAME: "Name",
  PROJECT_LIST_TABLE_TYPE: "Type",
  PROJECT_MENU_DELETE: "Delete building",
  PROJECT_MENU_EDIT: "Edit building",
  PROJECT_MENU_VIEW: "View building",
  PROJECT_NAME_REQUIRED: "Name required",
  PROJECT_NAME_TOO_SHORT: "Building name is too short!",
  PROJECT_TYPE_INVALID: "Invalid type",
  PROJECT_TYPE_REQUIRED: "Required field",
  PROJECTS_ADD_BUTTON: "New Building",
  PROJECTS_SEARCH_PLACEHOLDER: "Search",
  PROJECTS_TITLE: "Projects",
  REGISTER_ALLOW_POLICY: "Please agree to the policy",
  REGISTER_COMPANY_EMAIL_INVALID: "Invalid email",
  REGISTER_COMPANY_EMAIL_REQUIRED: "Required field",
  REGISTER_COMPANY_GENERAL: "Could not register business account, try again later",
  REGISTER_COMPANY_NAME_REQUIRED: "Required field",
  REGISTER_COMPANY_PHONE_REQUIRED: "Required field",
  REGISTER_EMAIL_IN_USE: "Email already in use",
  REGISTER_EMAIL_INVALID: "Invalid email",
  REGISTER_EMAIL_INVALID_INVITATION: "Email does not match the invitation",
  REGISTER_EMAIL_REQUIRED: "Required field",
  REGISTER_GENERAL: "Could not register user, try again later",
  REGISTER_NAME_REQUIRED: "Required field",
  REGISTER_NAME_TOO_SHORT: "The name is too short",
  REGISTER_PASSWORD_REQUIRED: "Required field",
  REGISTER_PASSWORD_TOO_SHORT: "The password is too short, at least 6 characters",
  RESET_GENERAL: "Expired",
  RESET_PASSWORD_NO_MATCH: "Passwords do not match",
  RESET_PASSWORD_TOO_SHORT: "The password is too short, at least 6 characters",
  REVISION_ADD_DSB_TO_TICKET_USER_TITLE: "Please attach a DesignBuilder file in order to solve the problem",
  REVISION_ADD_MISSING_FILE: "Drag a file or click here",
  REVISION_ADD_SUCCESSFULL: "Alternative added successfully",
  REVISION_BRIDGE_REQUIRED: "Required field",
  REVISION_CHANGED_GENERAL: "An error occurred",
  REVISION_CHECKED_STATUS_BUTTON_TEXT: "Run Simulation",
  REVISION_CREATED_BY_COLUMN: "Member",
  REVISION_CREDIT_ADD_DESCRIPTION: "Add Credits to generate reports",
  REVISION_CREDIT_ADD_NO: "Cancel",
  REVISION_CREDIT_ADD_TITLE: "Add Credits",
  REVISION_CREDIT_ADD_YES: "Pricing",
  REVISION_DAYLIGHTING_FORBIDDEN: "Do not add lighting control in a residential building or hotel",
  REVISION_DELETE_GENERAL: "The alternative cannot be deleted",
  REVISION_DELETE_NO: "Cancel",
  REVISION_DELETE_POPUP_DESCRIPTION: "Are you sure you want to delete this alternative?",
  REVISION_DELETE_POPUP_TITLE: "Delete Alternative",
  REVISION_DELETE_UNAUTHORIZED: "You are not authorized to delete this alternative",
  REVISION_DELETE_YES: "Delete",
  REVISION_DENSITY_REQUIRED: "Required field",
  REVISION_DUPLICATE_ZONE_NAMES: "Units need unique names",
  REVISION_EDIT_NEED_ANOTHER_FILE: "To update a failed revision you need another file",
  REVISION_ERROR_STATUS_BUTTON_TEXT: "Open Error",
  REVISION_File_COLUMN: "File",
  REVISION_FILE_INVALID: "The file is invalid, see instructions",
  REVISION_FILE_REQUIRED: "File required",
  REVISION_GENERAL: "Unable to save alternative",
  REVISION_H_REPORT_COLUMN: "H",
  REVISION_INDEX_COLUMN: "#",
  REVISION_INVALID_PATTERN_ZONE_NAMES: "Invalid names",
  REVISION_MENU_DELETE_REVISION: "Delete alternative",
  REVISION_MENU_DOWNLOAD_1045_REPORT: "Download report 1045",
  REVISION_MENU_DOWNLOAD_3D_REPORT: "Download 3D model",
  REVISION_MENU_DOWNLOAD_CSV_FILE: "Download CSV file",
  REVISION_MENU_DOWNLOAD_DETAILED_REPORT: "Download detailed report",
  REVISION_MENU_DOWNLOAD_DSB_MODEL_FILE: "Download DB model",
  REVISION_MENU_DOWNLOAD_H_REPORT: "Download report H",
  REVISION_MENU_DOWNLOAD_OUTPUT_JSON_FILE: "Download Output.json",
  REVISION_MENU_DOWNLOAD_RATING_REPORT: "Download rating report",
  REVISION_MENU_DOWNLOAD_RUN_FILE: "Download IDF file",
  REVISION_MENU_EDIT: "Edit alternative",
  REVISION_MENU_VIEW_RESULT: "View results",
  REVISION_MISSING_COOLING: "Units must have cooling",
  REVISION_MISSING_HEATING: "Units must have heating",
  REVISION_MISSING_ZONES: "No conditionned units",
  REVISION_NOTES_COLUMN: "Notes",
  REVISION_NOTES_GRADE: "Grade",
  REVISION_NOTES_RATING: "Rating",
  REVISION_OPEN_TICKET_ACTION: "Select action",
  REVISION_OPEN_TICKET_CLOSED_FAILED: "Error handled with user error",
  REVISION_OPEN_TICKET_CLOSED_FIXED: "Ticket handled successfully",
  REVISION_OPEN_TICKET_CLOSED_FXED: "Ticket handled successfully",
  REVISION_OPEN_TICKET_RE_PROCESS_REVISION: "Run simulation again",
  REVISION_OPEN_TICKET_REOPEN_REVISION: "Incorrect DSB file, please upload again",
  REVISION_OPEN_TICKET_REVISION_ERROR: "Edit here only in case of error",
  REVISION_PENDING_STATUS_BUTTON_TEXT: "Pending",
  REVISION_RATING_GRADE_COLUMN: "r",
  REVISION_RESOLVING_STATUS_ONGOING_TICKET_BUTTON_TEXT: "In treatment",
  REVISION_RESOLVING_STATUS_OPEN_TICKET_BUTTON_TEXT: "Open Ticket",
  REVISION_RESULT_1045_TABLE: "Thermal resistance according to SI-1045 Table 1",
  REVISION_RESULT_CALCULATION_H_PLANNING_UNITS: "H Calculation for Residential units",
  REVISION_RESULT_DOWNLOAD_BUTTON: "Download Reports",
  REVISION_RESULT_ENERGY_RATING: "Energy Rating",
  REVISION_RESULT_ENERGY_RATING_PLANNING_UNIT: "Energy rating for units",
  REVISION_RESULT_ESTIMATED_SAVINGS: "Estimated savings",
  REVISION_RESULT_ESTIMATED_SAVINGS_5_YEARS: "for 5 years",
  REVISION_RESULT_H_REPORT_MAP_FAIL: "Fail",
  REVISION_RESULT_H_REPORT_MAP_PASS: "Pass",
  REVISION_RESULT_RETURN_BUTTON: "Back",
  REVISION_RESULT_UNLOCK_BUTTON: "Generate Reports",
  REVISION_RESULT_WEIGHTED_SCORE: "Weighted Grade",
  REVISION_RESULTS_GENERAL: "Unable to display results",
  REVISION_RUN_GENERAL: "Could not run alternative",
  REVISION_RUN_NOT_ENOUGH_CREDITS: "Could not run alternative",
  REVISION_SUCCESS_DOWNLOAD_BUTTON_TEXT: "Download Reports",
  REVISION_SUCCESS_TOPUP_BUTTON_TEXT: "Add Credits",
  REVISION_SUCCESS_UNLOCK_BUTTON_TEXT: "Generate Reports",
  REVISION_TICKET_ADD_SUCCESS: "Ticket added successfully",
  REVISION_UNLOCK_DESCRIPTION: "$$1 credits will be deducted from the business account",
  REVISION_UNLOCK_GENERAL: "Unable to show alternative, credits required",
  REVISION_UNLOCK_NO: "No, Thanks",
  REVISION_UNLOCK_TITLE: "Want to generate reports?",
  REVISION_UNLOCK_YES: "Yes, I want",
  REVISION_UNSUPPORTED_AIRFLOW: "Inappropriate ventilation setting",
  REVISION_UPDATE_SUCCESSFULL: "Alternative updated successfully",
  REVISION_UPDATED_DATE: "Updated Date",
  REVISION_UPLOAD_GENERAL: "An error occurred",
  REVISION_VALIDATING_STATUS_BUTTON_TEXT: "Validating",
  REVISIONS_ADD_BUTTON: "New Revision",
  REVISIONS_DROP_FILE_PLACEHOLDER: "Drag a file or click here",
  SAVE_ORGANIZATION_GENERAL: "Business account could not be saved",
  SAVE_USER_GENERAL: "User could not be saved",
  SIGNUP_ADDRESS: "Address",
  SIGNUP_ADDRESS_HELPTEXT: "This address will appear in your reports",
  SIGNUP_BUSINESS_NAME: "Business Name",
  SIGNUP_BUSINESS_NAME_HELPTEXT: "This name will appear in your reports",
  SIGNUP_EMAIL: "Email",
  SIGNUP_EMAIL_HELPTEXT: "This email will appear in your reports",
  SIGNUP_HEADER: "Leave business details",
  SIGNUP_HEADER2: "to create your Free Account",
  SIGNUP_INVITE_HEADER: "Create a profile",
  SIGNUP_INVITE_HEADER2: "to join your business",
  SIGNUP_PHONE: "Phone",
  SIGNUP_PHONE_BUTTON: "Submit Information",
  SIGNUP_PHONE_HELPTEXT: "This phone will appear in your reports",
  TERMS_OF_USE: "Terms of use",
  TERRAIN_OPTION_CITY: "City",
  TERRAIN_OPTION_COUNTRY: "Country",
  TERRAIN_OPTION_SUBURBS: "Suburbs",
  TICKET_MISSING_SUCCESS_FILES: "All files are required in case of success",
  TOKEN_FORGOT_EXPIRED: "Token expired, pls reset again",
  TOKEN_FORGOT_INVALID: "Bad token, try resetting your password",
  TOKEN_GENERAL: "Unable to verify new password",
  TOKEN_INVITE_INVALID: "Bad token, try inviting you again",
  TYPE_EDUCATIONAL: "Educational",
  TYPE_HOTEL: "Hotel",
  TYPE_OFFICE: "Office",
  TYPE_OFFICE_CORE_SHELL: "Office Core & Shell",
  TYPE_RESIDENTIAL: "Residential",
  USER_EDIT_EMAIL_BTN_TEXT: "Save",
  USER_EDIT_EMAIL_CURRENT_EMAIL: "Current Email",
  USER_EDIT_EMAIL_CURRENT_EMAIL_HELPER_TEXT: "This email will appear in your reports",
  USER_EDIT_EMAIL_NEW_EMAIL: "New Email",
  USER_EDIT_EMAIL_NEW_EMAIL_CONFIRM: "Confirm New Email",
  USER_EDIT_EMAIL_NEW_EMAIL_CONFIRM_HELPER_TEXT: "New Email Verification",
  USER_EDIT_EMAIL_NEW_EMAIL_HELPER_TEXT: "Enter a new email",
  USER_EDIT_EMAIL_UPDATE_HEADER: "Email Update",
  USER_EDIT_HEADER: "Personal Area",
  USER_EDIT_PASSWORD_CURRENT_PASS: "Current Password",
  USER_EDIT_PASSWORD_NEW_PASS: "New Password",
  USER_EDIT_PASSWORD_NEW_PASS_CONFIRM: "Confirm New Password",
  USER_EDIT_PASSWORD_UPDATE_BTN_TEXT: "Save",
  USER_EDIT_PASSWORD_UPDATE_HEADER: "Password Update",
  USER_EDIT_PI_BTN_TEXT: "Save",
  USER_EDIT_PI_HEADER: "Personal Information",
  USER_EDIT_PI_MOBILE: "Mobile",
  USER_EDIT_PI_MOBILE_HELPER_TEXT: "Mobile for support only",
  USER_EDIT_PI_NAME: "Full Name",
  USER_EDIT_PI_NAME_HELPER_TEXT: "This name will appear in your reports",
  USER_EDIT_PI_ROLE: "Position",
  USER_EDIT_PI_ROLE_HELPER_TEXT: "This position will appear in your reports",
  USER_PHONE_REQUIRED: "Required field",
  USER_ROLE_REQUIRED: "Required field",
  VALIDATE_INVITE_GENERAL: "Invalid invitation",
  
  MUI_ROWS_PER_PAGE: "Rows per page:",
  MUI_PAGINATION_RANGE: "{{from}}-{{to}} of {{count}}",
  MUI_PAGINATION_NEXT_PAGE: "Next page",
  MUI_PAGINATION_PREVIOUS_PAGE: "Previous page",
  ADMIN_DASHBOARD_CREDITS: 'Credits',
  ADMIN_DASHBOARD_PURCHASED: 'Total',
  ADMIN_DASHBOARD_TOTAL_PROJECTS: 'Projects',
  ADD_PROJECT_VERSION: 'Version',
  ADD_PROJECT_CONSTRUCTION: 'Construction',
  TYPE_CONSTRUCTION_NEW: "New",
  TYPE_CONSTRUCTION_EXIST: "Exist",
}
}