import { createTheme, ThemeProvider } from '@mui/material';
import RoutesComponent from 'components/RoutesComponent';
import { AuthProvider } from 'context/AuthContext';
import UseLanguageContext from 'context/LanguageContext';
import { useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";



const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const { language } = useContext(UseLanguageContext);
  useEffect(() => {
    document.body.setAttribute('dir', language === 'en' ? 'ltr' : 'rtl');
  }, [language]);

  const THEME = createTheme({
    typography: {
      fontFamily: ['Assistant', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 700,
    },
    palette: {
      primary: {
        main: '#0E66F0',
        light: '#0E66F0',
        dark: '#0052D3',
      },
      info: {
        main: '#0E66F0',
        light: '#0E66F0',
        dark: '#0052D3',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...{
             /*  ':hover': {
                boxShadow: '0px 1px 5px rgba(63, 68, 82, 0.3)',
                cursor: 'pointer',
                border: '1px solid #DEE0E2',
                background: '#FFF',
              }, */
            },
          }),
        },
      },
  
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            direction: language === 'en' ? 'ltr' : 'rtl',
          },
          toolbar: {
            flexDirection: language === 'en' ? 'row' : 'row-reverse',
          },
          actions: {
            flexDirection: language === 'en' ? 'row' : 'row-reverse',
          },
          selectLabel: {
            marginRight: language === 'en' ? '0' : 'auto',
            marginLeft: language === 'en' ? 'auto' : '0',
          },
        },
      },
      /* MuiTablePagination: {
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',
            width: '90vw',
            marginTop: '1.5rem',
            paddingLeft: 0,
          },
          toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          },
          selectRoot: {
            order: 4,
            background: ' #FFFFFF',
            border: '1px solid rgba(196, 196, 196, 0.5)',
          },
          spacer: {
            flex: 'none',
            display: 'none',
          },
          actions: {
            order: 1,
            margin: 0,
          },
          displayedRows: {
            order: 2,
          },
          selectLabel: {
            order: 3,
            flex: '3 1 auto',
            textAlign: 'end',
          },
        },
      },
      */
      MuiTableContainer: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      }, 
      MuiDialog: {
        styleOverrides: {
          paper: {
            width: '410px',
          },
          root: {
            wordWrap: 'break-word',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            width: '410px',
            minWidth: 'auto',
            textAlign: language === 'he' ? 'right' : 'left',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: language === 'he' ? 'right' : 'left',
          },
        },
      },
    },
    direction: language === 'en' ? 'ltr' : 'rtl',
  });



  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
          <ThemeProvider theme={THEME}>
            <RoutesComponent />
          </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
