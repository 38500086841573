import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MainLogo from 'assets/main-logo.svg';
import UseAuthContext from 'context/AuthContext';
import UseLanguageContext from 'context/LanguageContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { USER_ROLES } from 'utils/constants';
import { calcTotalAccountPoints } from 'utils/utils';
import ButtonCustom from './ButtonCustom';
import HeaderMenu from './HeaderMenu';
import LanguageSwitcher from './LanguageSwitcher';

const Header = () => {
  const { isAuthenticated, organizations, user, logout, role, adminLogout } =
    React.useContext(UseAuthContext);
    const { language } = React.useContext(UseLanguageContext);
  const [accountPoints, setAccountPoints] = React.useState(0);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [hideBusinessLogo, setHideBusinessLogo] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const adminRoutes = [
    {
      path: '/admin/business/:businessId/project/:projectId/edit-ticket-dsb/:revisionId',
    },
    {
      path: '/admin/business/:businessId/project/:projectId/revision/:revisionId/:ticketId',
    },
    {
      path: '/admin/dashboard',
    },
    {
      path: '/admin/tickets',
    },
    {
      path: '/admin/add-business',
    },
  ];



  React.useEffect(() => {
    const matchedRoutes = matchRoutes(adminRoutes, currentLocation.pathname);
    if (matchedRoutes && matchedRoutes.length > 0) {
      setHideBusinessLogo(true);
    } else {
      setHideBusinessLogo(false);
    }
  }, [currentLocation.pathname]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    let accPoints = 0;
    if (organizations) {
      accPoints = calcTotalAccountPoints(organizations);
    }
    setAccountPoints(accPoints);
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutHandler = (e: any) => {
    e.preventDefault();
    logout();
  };

  return (
    <Box className='header-body'>
      <AppBar
        position='static'
        color='transparent'
        style={{
          boxShadow: 'none',
          minHeight: '70px',
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Link to='/'>
              <img src={MainLogo} alt='LOGO' />
            </Link>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              gap: 3,
              display: 'flex',
              mx:"1rem"
            }}
          >
            <HeaderMenu isForHeader />
          </Box>
          <Box >
          <LanguageSwitcher />
          </Box>
          <Box
            sx={{
              display: !isAuthenticated ? 'flex' : 'none',
              justifyContent: 'space-around',
              gap: 1,
              alignItems: 'center',
            }}
          >
           
            <ButtonCustom
              variant='contained'
              size='small'
              color='primary'
              onClick={(e: any) => {
                e.preventDefault();
                navigate('/login');
              }}
            >
              Login
            </ButtonCustom>
          </Box>
          <Box
            sx={{
              display: role === USER_ROLES.ADMIN ? 'flex' : 'none',
              mx:3
            }}
          >
            <ButtonCustom
              size='small'
              sx={{
                width: '80px',
              }}
              variant='contained'
              color='primary'
              className='secondary-btn'
              onClick={(e: any) => {
                adminLogout();
              }}
            >
              {t('HEADER_PROFILE_MENU_LOGOUT')}
            </ButtonCustom>
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              display: isAuthenticated && !hideBusinessLogo ? 'flex' : 'none',
            }}
          >
            <Tooltip title=''>
              <Box onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  variant='square'
                  alt='User Image'
                  src={organizations?.image_url}
                  sx={{ width: '70px', minHeight: '70px', padding:'10px' }}
                />
              </Box>
            </Tooltip>
            <Menu
              sx={{ mt: '55px', width:"100%" }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: language === 'he' ? 'left' : 'right',
              }}
              
              transformOrigin={{
                vertical: 'top',
                horizontal: language === 'he' ? 'left' : 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box className='profile-menu-container'>
                <Box className='profile-menu-header'>
                <Box
                    onClick={() => {
                      if (role === USER_ROLES.ADMIN) {
                        navigate(`/edit/business/${organizations?.id}`);
                      } else {
                        navigate(`/edit/business`);
                      }
                      handleCloseUserMenu();
                    }}
                    sx={{
                      cursor: 'pointer',
                    
                    }}
                  >
                    <Avatar
                      alt='User Image'
                      variant='square'
                      sx={{ width: '85px', minHeight: '85px'}}
                      src={organizations?.image_url}
                    />
                  </Box>
                  <Box sx={{ }} className='profile-menu-header-left'>
                    <Typography variant='subtitle2' component='p'>
                      {user?.name}
                    </Typography>
                    <Typography variant='subtitle2' component='p'>
                      {organizations?.company_name}
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      component='p'
                      className='points'
                    >
                       {accountPoints ?? 0} Credits
                    </Typography>
                  </Box>
               
                </Box>
                <Box
                  className='profile-menu-footer'
                 
                >
                  {role !== USER_ROLES.ADMIN ? (
                    <ButtonCustom
                      variant='outlined'
                      size='small'
                      sx={{
                        width: '100px',
                      }}
                      onClick={logoutHandler}
                      className='secondary-btn'
                    >
                      {t('HEADER_PROFILE_MENU_LOGOUT')}
                    </ButtonCustom>
                  ) : (
                    <ButtonCustom
                      size='small'
                      sx={{
                          width: '100px',
                        }}
                      variant='outlined'
                      onClick={() => {
                        navigate(
                          `/admin/business/${organizations?.id}/projects`
                        );
                        handleCloseUserMenu();
                      }}
                      className='secondary-btn'
                    >
                      {t('HEADER_PROJECT_LIST')}
                    </ButtonCustom>
                  )}
                </Box>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
        <Divider orientation="horizontal"/>

      </AppBar>
    </Box>
  );
};
export default Header;
