import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from '@mui/material';
import SignUP from 'assets/join-bg.png';
import ButtonCustom from 'components/ButtonCustom';
import PasswordTextField from 'components/PasswordTextField';
import UseAuthContext from 'context/AuthContext';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { signupUsingInvitation } from 'services/invitation-signup';
import { getErrorHtmlContent } from 'utils/utils';

const SignUpByInvite = () => {
  const location: any = useLocation().state;
  const navigate = useNavigate();
  const [email, setEmail] = useState(location?.email);
  const [orgID, setOrgID] = useState(location?.organisationID);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [receiveEmail, setReceiveEmail] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const { t } = useTranslation();
  const { loginAfterInvitationProcess } = useContext(UseAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (location === null || !location.email || !location.organisationID) {
      navigate('/', {
        replace: true,
      });
    }
  }, []);

  const signUpHandler = async (e: any) => {
    try {
      e.preventDefault();
      setisLoading(true);
      const response = await signupUsingInvitation({
        email: email,
        password: password,
        name: name,
        allow_policy: terms,
        allow_mailing: receiveEmail,
        organization: orgID,
      });
      await loginAfterInvitationProcess(response.data);
      setisLoading(false);
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        mt: '2rem',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: { xs: 4, md: 2 },
          }}
        >
          <Typography variant='h4'>{t('SIGNUP_INVITE_HEADER')}</Typography>
          <Typography variant='h6'>{t('SIGNUP_INVITE_HEADER2')}</Typography>
        </Box>
        <Box component='form' sx={{ mx: '2rem' }}>
          <TextField
            margin='normal'
            fullWidth
            variant='standard'
            id='full_name'
            name='full_name'
            autoFocus
            label={t('INVITE_SIGNUP_NAME')}
            helperText={t('INVITE_SIGNUP_NAME_HELPER_TEXT')}
            autoComplete='off'
            autoCorrect='off'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin='normal'
            variant='standard'
            fullWidth
            name='email'
            label={t('INVITE_SIGNUP_EMAIL')}
            helperText={t('INVITE_SIGNUP_EMAIL_HELPER_TEXT')}
            id='email'
            type='email'
            autoComplete='off'
            autoCorrect='off'
            disabled={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <PasswordTextField
            name='password'
            label={t('INVITE_SIGNUP_PASSWORD')}
            fullWidth
            variant='standard'
            sx={{ marginTop: '2rem' }}
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
          <Box
            sx={{
              mt: '2rem',
              display: 'flex',
              alignItems: 'center',
              gap: '0.2rem',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  value={terms}
                  onChange={(event: any, checked: boolean) => {
                    setTerms(checked);
                  }}
                />
              }
              sx={{
                mr: '0rem',
              }}
              label={t('INVITE_SIGNUP_TERMS')}
            />
            <a
              href='https://designbuilder.co.il/policy/'
              target='_blank'
              className='term_link'
            >
              {t('INVITE_SIGNUP_TERMS_LINK')}
            </a>
          </Box>
          <br></br>
          <FormControlLabel
            control={
              <Checkbox
                checked={receiveEmail}
                onChange={(event: any, checked: boolean) => {
                  setReceiveEmail(checked);
                }}
              />
            }
            label={t('INVITE_SIGNUP_EMAIL_RECEIVE')}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <ButtonCustom
              color='primary'
              sx={{ mt: 3, mb: 2 }}
              type='submit'
              variant='contained'
              onClick={signUpHandler}
            >
              {t('INVITE_SIGNUP_BUTTON')}
            </ButtonCustom>
           
          </Box>
        </Box>
      </Box>

      <Box
        component='img'
        src={SignUP}
        sx={{
          Width: '665px',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
          marginTop: '-1rem',
          marginBottom: '-1rem',
        }}
      ></Box>
    </Box>
  );
};

export default SignUpByInvite;
