import { Box, Typography } from '@mui/material';
import MainLogo from 'assets/main-logo-white.svg';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';

export const AuthenticationLayout = ({ page = 'login' }) => {
  const { t } = useTranslation();
  const headerText =
    page === 'login'
      ? t('LOGIN_HEADER')
      : page === 'adminLogin'
      ? t('ADMIN_LOGIN_HEADER')
      : t('FORGOT_HEADER');
  const headerClass =
    page === 'login' ? 'login-box-container' : 'login-box-container-smaller';

  return (
    <>
      <Box className='login-container'>
        <Box className='login-background' />
        <Box className='login-content-container'>
          <Box className={headerClass}>
            <Box className='login-content'>
              <Typography variant='h6'>{headerText}</Typography>
              <Outlet />
            </Box>
          </Box>
          <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
            <img src={MainLogo} alt='LOGO' />
            {page === 'adminLogin' ? (
              <></>
            ) : (
              <Typography
                variant='body2'
                component={'h2'}
                color='white'
                sx={{ zIndex: '3' }}
              >
                {t('LOGIN_QUOTE')} |{' '}
                <Link to='/signup'>{t('LOGIN_REGISTER_BTN_TEXT')}</Link>
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};