import AddIcon from '@mui/icons-material/Add'; // Import the icon you want to use
import { Box, Button, ButtonProps } from '@mui/material';
import React from 'react';

interface ButtonIconProps extends ButtonProps {
	icon?: React.ReactNode;
}

const ButtonIcon: React.FC<ButtonIconProps> = ({ icon, children, ...props }) => {
	return (
		<Button
			{...props}
			sx={{
				width: '170px',
				height: '50px',
				fontSize: '18px',
				padding: 0,
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
				...props.sx,
			}}
		>
			{icon || <AddIcon sx={{ fontSize: '40px', p: 0, m: 0, mr: "1" }} />}
			<Box sx={{ flexGrow: 1, display: "flex", alignItems: 'center', justifyContent: "flex-start" }}>
				{children}
			</Box>
		</Button>
	);
};

export default ButtonIcon;