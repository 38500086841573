import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

export const Layout = () => {
  return (
    <div>
      <Container maxWidth="lg" className='page bg-grey'>
        <Box className='header-container'>
          <Header />
        </Box>
        <Box className='body-container'>
          <Outlet />
        </Box>
        <Box component='footer'>
          <Footer />
        </Box>
      </Container>
    </div>
  );
};
