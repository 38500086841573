import { Facebook, LinkedIn, YouTube } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import HeaderMenu from './HeaderMenu';
const Footer = () => {
  return (
    <Box
      className='footer'
      sx={{
        my: 3,
        marginRight: '2rem',
      }}
    >
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item xs={10}>
          <Box
            sx={{
              flexGrow: 1,
              gap: 3,
              marginLeft: '2rem',
              display: 'flex',
            }}
            className='menu-items'
          >
            <HeaderMenu isForHeader={false} />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '2rem',
            }}
          >
            <a
              href='https://www.facebook.com/DesignBuilderIsrael/'
              target='_blank'
              rel='noreferrer'
            >
              <Facebook />
            </a>
            <a
              href='https://www.youtube.com/playlist?list=PLu8h-kasuylHzBBmA_3pCtdMYDnmuyF-A'
              target='_blank'
              rel='noreferrer'
            >
              <YouTube />
            </a>
           
            <a
              href='https://www.linkedin.com/company/designbuilder-israel/about/?viewAsMember=true'
              target='_blank'
              rel='noreferrer'
            >
              <LinkedIn />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
