import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Container, Tab, Typography } from '@mui/material';
import BusinessContact from 'pages/BusinessAccount/BusinessContact';
import BusinessCredits from 'pages/BusinessAccount/BusinessCredits';
import BusinessSetting from 'pages/BusinessAccount/BusinessSetting';
import BusinessStaff from 'pages/BusinessAccount/BusinessStaff';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GetUserOrganization } from 'services/auth-service';
import AddBusinessContact from './AddBusinessContact';
import AddBusinessCredits from './AddBusinessCredits';
import AddBusinessSetting from './AddBusinessSetting';
import AddBusinessStaff from './AddBusinessStaff';

const AddBusinessAccount = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState('SETTING');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [organisation, setOrganisation] = useState(null);

  const OrganisationCallback = async (organisationID: any) => {
    // const OrganisationData = await GetUserOrganization(organisationID);
    // setOrganisation(OrganisationData.data);
    // Better way to navigate once we've some info from Admin regarding new business account
    navigate(`/edit/business/${organisationID}`);
  };

  return (
    <Box>
      <Typography variant='h4' sx={{ mb: '1rem' }}>
        {t('ADMIN_ADD_BUSINESS')}
      </Typography>
      <Box sx={{ bgcolor: '#fff' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '50px' }}>
            <TabList onChange={handleChange} aria-label='lab API tabs example'>
              <Tab label={t('BUSINESS_EDIT_Setting_HEADER')} value='SETTING' />
              <Tab label={t('BUSINESS_EDIT_CONTACT_HEADER')} value='CONTACT' />
              <Tab label={t('BUSINESS_EDIT_CREDIT_HEADER')} value='CREDITS' />
              <Tab
                label={t('BUSINESS_EDIT_STAFF_HEADER')}
                value='STAFF'
                disabled
              />
            </TabList>
          </Box>
          <TabPanel value='SETTING' className='tab-content'>
            <AddBusinessSetting organisationCallback={OrganisationCallback} />
          </TabPanel>
          <TabPanel value='CONTACT' className='tab-content'>
            <AddBusinessContact
              organisation={organisation}
              organisationCallback={OrganisationCallback}
            />
          </TabPanel>
          <TabPanel value='CREDITS' className='tab-content'>
            <AddBusinessCredits
              organizations={organisation}
              organisationCallback={OrganisationCallback}
            />
          </TabPanel>
          <TabPanel value='STAFF' className='tab-content'>
            <></>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default AddBusinessAccount;
