import { Box, TextField } from '@mui/material';
import ButtonCustom from 'components/ButtonCustom';
import Restrict from 'components/Restrict';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateOrganisation } from 'services/business-setting-service';

const AddBusinessContact = (props: any) => {
  const { organisation, organisationCallback } = props;
  const { t } = useTranslation();

  const [phone, setPhone] = useState(organisation?.company_phone);
  const [email, setEmail] = useState(organisation?.company_email);
  const [isLoading, setisLoading] = useState(false);

  const onClickHandler = async (e: any) => {
    try {
      setisLoading(true);
      e.preventDefault();
      const data = {
        ...organisation,
        company_phone: phone,
        company_email: email,
        id: organisation?.id,
      };
      const response = await updateOrganisation(data);

      if (response.status === 200) {
        setisLoading(false);
        organisationCallback(data.data.id);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  return (
    <Box component='form' sx={{ mx: '2rem' }} className='tab-content-form'>
      <TextField
        margin='normal'
        fullWidth
        variant='standard'
        id='email'
        name='email'
        type='email'
        autoFocus
        label={t('EDIT_BUSINESS_SETTING_EMAIL')}
        autoComplete='off'
        autoCorrect='off'
        helperText={t('EDIT_BUSINESS_SETTING_EMAI_HELPER_TEXT')}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        margin='normal'
        fullWidth
        variant='standard'
        id='phone'
        name='phone'
        type='tel'
        label={t('EDIT_BUSINESS_SETTING_PHONE')}
        autoComplete='off'
        autoCorrect='off'
        helperText={t('EDIT_BUSINESS_SETTING_HONE_HELPER_TEXT')}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <Restrict forbidden={['viewer']}>
        <ButtonCustom
          color='primary'
          sx={{ mt: 3, mb: 2 }}
          type='submit'
          variant='contained'
          size='large'
          onClick={onClickHandler}
          disabled={!organisation}
        >
          {t('EDIT_BUSINESS_SETTING_SAVE_BUTTON')}
        </ButtonCustom>
      </Restrict>
    </Box>
  );
};

export default AddBusinessContact;
