import { Box, TextField, Typography } from '@mui/material';
import ComapnySVG from 'assets/company-reg-bg.svg';
import ButtonCustom from 'components/ButtonCustom';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { signupOrganisation } from 'services/auth-service';
import { IErrorResponse, IORGRegistrationRequest } from 'utils/contracts';
import { getErrorHtmlContent } from 'utils/utils';





const SignUP = () => {
  const [company_name, setCompanyName] = useState('');
  const [company_address, setAddress] = useState('');
  const [company_email, setEmail] = useState('');
  const [company_phone, setPhone] = useState('');
  const [isLoading, setisLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const SignupHandler = async (e: any) => {
    try {
      setisLoading(true);
      e.preventDefault();
      await signUpMutation.mutateAsync({
        company_name,
        company_address,
        company_email,
        company_phone,
      });
      enqueueSnackbar(t('ORG_REGISTRATION_SUCCESS_MESSAGE'), {
        variant: 'info',
      });
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
    }
  };

  const signUpMutation = useMutation({
    mutationFn: async (data: IORGRegistrationRequest) => {
      const loginResponse = await signupOrganisation(data);
      return loginResponse;
    },
    onSuccess(data) {
      const responseData = data.data;
      resetForm();
      return responseData;
    },
    onError(error: IErrorResponse, variables, context) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error, variables, context);
      }
    },
    onSettled() {
      setisLoading(false);
    },
  });

  const resetForm = () => {
    setCompanyName('');
    setAddress('');
    setEmail('');
    setPhone('');
  };



/*   if (isLoading){
    return <Spinner />
  } */
  return (
    <>
     
      <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: { xs: 4, md: 2 },
          }}
        >
          <Typography variant='h4'>{t('SIGNUP_HEADER')}</Typography>
          <Typography variant='h6'>{t('SIGNUP_HEADER2')}</Typography>
        </Box>
        <Box component='form' sx={{ mx: '2rem' }}>
          <TextField
            margin='normal'
            fullWidth
            variant='standard'
            id='company_name'
            name='company_name'
            autoFocus
            label={t('SIGNUP_BUSINESS_NAME')}
            helperText={t('SIGNUP_BUSINESS_NAME_HELPTEXT')}
            autoComplete='off'
            autoCorrect='off'
            value={company_name}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <TextField
            margin='normal'
            variant='standard'
            fullWidth
            name='address'
            label={t('SIGNUP_ADDRESS')}
            helperText={t('SIGNUP_ADDRESS_HELPTEXT')}
            id='address'
            type='address'
            autoComplete='off'
            autoCorrect='off'
            value={company_address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <TextField
            margin='normal'
            variant='standard'
            fullWidth
            name='email'
            label={t('SIGNUP_EMAIL')}
            helperText={t('SIGNUP_EMAIL_HELPTEXT')}
            id='email'
            type='email'
            autoComplete='off'
            autoCorrect='off'
            value={company_email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin='normal'
            variant='standard'
            fullWidth
            name='phone'
            label={t('SIGNUP_PHONE')}
            helperText={t('SIGNUP_PHONE_HELPTEXT')}
            id='phone'
            autoComplete='off'
            autoCorrect='off'
            value={company_phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <ButtonCustom
            color='primary'
            sx={{mt: 2}}
            type='submit'
            variant='contained'
            onClick={SignupHandler}
          >
            {t('SIGNUP_PHONE_BUTTON')}
          </ButtonCustom>
        </Box>
      </Box>
      <img
        src={ComapnySVG}
        style={{
          width: '665px',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
          marginTop: '-1rem',
          marginBottom: '-1rem',
        }}
      />
    </Box>
    </>
  );
};

export default SignUP;